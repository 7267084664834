import "babel-polyfill"
import "isomorphic-fetch"

import React from 'react'
import Helmet from 'react-helmet'

import { StaticQuery, graphql } from "gatsby"

import Header from '../Header'
import Footer from '../Footer'

import favicon from './images/favicon.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/main.scss';
import './theme/custom.scss';

const Layout = ({ children }) => (

  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
        allSocialJson {
          edges {
            node {
              url
              icon
            }
          }
        }
        prismicNavbar {
          data {
            links {
              title
              link {
                url
              }
            }
          } 
        }
        prismicSiteAlert {
          data {
            alert { html }
          } 
        }
        prismicFooter {
          data {
            links {
              title 
              link {
                url
              }
            }
          }
        }
      }`
    }
    render={ data => (
      <div>
        <Helmet htmlAttributes={{"lang": "en", "id" : "sje"}}
          script={[
          {
           type: 'text/javascript',
           innerHTML: 'window.prismic = { endpoint: "https://sje-corp-site.cdn.prismic.io/api/v2" }'
          },
          {
           type: 'text/javascript',
           src: '//static.cdn.prismic.io/prismic.min.js'
          }
          ]}>      
          <title>{ data.site.siteMetadata.title }</title>
          <meta name="description" content={ data.site.siteMetadata.description } />
          <meta name="keywords" content={ data.site.siteMetadata.keywords } />
          <link rel="icon" type="image/png" href={ favicon } sizes="640x640" />
        </Helmet>
        <Header 
          navItems={ data.prismicNavbar.data.links }
          socialItems={ data.allSocialJson.edges }
          siteAlert={ data.prismicSiteAlert.data.alert.html }
        >
        </Header>
        { children }
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossOrigin="anonymous"></link>
        <Footer 
          navItems={ data.prismicFooter.data.links }
          socialItems={ data.allSocialJson.edges }>
        </Footer>
      </div>
    )}
  />


  
)

export default Layout
