import React from 'react';
import logo from './layout/images/SJE_logo_new.png';
import yourAccount from './layout/images/your-account-icon-red-white.png';
import { Button, Col, Row, Container, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';

class Header extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggleMain = this.toggleMain.bind(this);
    this.loadSearchPage = this.loadSearchPage.bind(this);
    this.handleInputKeyPress = this.handleInputKeyPress.bind(this);

    this.state = {
      isMainOpen: false,
      searchValue: ''
    };
  }

  toggleMain() {
    this.setState({
      isMainOpen: !this.state.isMainOpen
    });
  }

  loadSearchPage() {
    if( typeof window !== `undefined` ) window.location = `/search?q=${this.state.searchValue}`;
  }

  loadMyAccountPage() {
    if( typeof window !== `undefined` ) window.open('https://myaccount.sjenergy.com/user/login');
  }

  handleInputChange = (e) => {
    this.setState({
        searchValue: e.target.value
    })
  }

  handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.loadSearchPage();
    }
  }

  render() {

    const siteTitle = this.props.siteTitle;
    const siteAlert = this.props.siteAlert;

    const navItems = this.props.navItems.map( (item, index) => {
        return <li key={ index } className="sub">
                <a href={ item.link.url }> { item.title }</a>
               </li>;
    });
    
    const socialItems = this.props.socialItems.map( edge => {
        return { url: edge.node.url, icon: edge.node.icon }
    });

    const socialMarkup = socialItems.map( (item, index) => {
        return (<li key={ index }>
                    <a href={ item.url } target="_blank" rel="noopener noreferrer">
                        <i className={ item.icon }></i>
                    </a>
                </li>);
    });

    return (
      <header id="top" className="c-header-1 -bg-white -js-header">
          <div className="c-header-1-container">
              <div className="c-header-1-inner">
                  <div>
                      <div className="c-header-1-middle">
                          <div className="header-menu-bar">
                              <nav className={"c-header-1-nav " + (this.state.isMainOpen? 'active' : '' )}>
                                  <ul>
                                      { navItems }
                                  </ul>
                              </nav>
                              <div className="c-header-1-icon-toggle" onClick={this.toggleMain}>
                                  { this.state.isMainOpen ?
                                    <i className="fa fa-times"></i> : 
                                    <i className="fa fa-bars"></i>
                                  }
                                  <div className="c-header-1-nav-label"></div>
                              </div>
                          </div>

                          <div className="header-social">
                              <ul className="d-none d-sm-block">
                                  { socialMarkup }
                              </ul>
                          </div>
                                            
                      </div>
                      { siteAlert && siteAlert.length > 9 ?
                          <div className="site-alert" dangerouslySetInnerHTML={ { __html: siteAlert } }>
                          </div>
                          : ""}
                  </div>
              </div>
          </div>
          <Container>
            <Row className="my-4">
                <Col xs="12" md="4">
                    <div className="logo mb-4">
                        <a href="/" title="Home" >
                            <img className="home-logo-img" src={logo} alt={ siteTitle } />
                        </a>
                    </div>
                </Col>
                <Col xs="12" md={{ size: 7, offset: 1 }}>
                    <Row noGutters className="mt-0 mt-md-5">
                        <Col sm="8">
                            <FormGroup className="mr-sm-2">
                                <InputGroup>
                                    <Input placeholder="Search" className="search border-dark" bsSize="lg" 
                                        onKeyPress={ this.handleInputKeyPress }
                                        onChange={ this.handleInputChange } />                                        
                                    <InputGroupAddon addonType="append">
                                        <Button className="search" outline onClick={ this.loadSearchPage }>Search</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm="4">
                            <Button className='my-account' block outline color="danger" size="lg" onClick={ this.loadMyAccountPage }>
                                <img style={{ maxHeight: '26px' }} src={yourAccount} alt="My Account" /> &nbsp; My Account</Button>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </Container>
    
      </header>
    );
  }
}

export default Header;