import React from "react";

class Footer extends React.Component {
    
    render () {
        
        const socialItems = this.props.socialItems.map( edge => {
            return { url: edge.node.url, icon: edge.node.icon }
        });

        const socialMarkup = socialItems.map( (item, index) => {
            return <li key={ index }>
                        <a href={ item.url } target="_blank" rel="noopener noreferrer">
                            <i className={ item.icon }></i>
                        </a>
                    </li>;
        });

        const navMarkup = this.props.navItems.map( (item, index) => {
            return <a key={ index } href={ item.link.url } title={ item.title }>{ item.title }</a>
        })

        return (
            <div>
                <footer className="c-footer-1 mt-5">
                    <div>
                        <div className="footer-contact">
                            { navMarkup }
                        </div>
                        <div className="footer-copyright-social">
                            <div className="footer-social">
                                <ul>
                                    { socialMarkup }
                                </ul>
                            </div>
                            <div>
                                <p>&copy; Saint John Energy - { new Date().getFullYear() }<br />All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;